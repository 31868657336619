<template>
 <div>
        <div style="padding-top: 10%">
        <v-col xs12>
            <v-row align-center justify-center row fill-height>
                <div>
                  <v-alert :value="show" :color="color" :icon="icon" outline>{{message}}</v-alert>
                    <div class="text-xs-center">
                        <v-card flat>
                            <v-progress-circular v-if="!show"  :size="100" :width="7" color="primary"  indeterminate></v-progress-circular>
                        </v-card>                   
                    </div> 
                </div>
            </v-row>
        </v-col>
    </div>
 </div>
</template>
<script>
import api from '@/api/autenticacion'
export default {
  data: () => {
    return {
      show: false,
      message: '',
      color: 'success',
      icon: ''
    }
  },
  mounted () {
    let token = window.location.href.split('?')[1]
    var data = new FormData()
    data.append('token', token)
    api.validarCorreo(data)
    .then(response => {
      this.message = response.data
      this.color = 'success'
      this.show = true
      this.icon = 'check_circle'
      setTimeout(() => this.$router.push({path: '/'}), 5000)
    })
    .catch(error => {
      this.message = error.response.data.Message
      this.color = 'error'
      this.show = true
      this.icon = 'warning'
      setTimeout(() => this.$router.push({path: '/'}), 5000)
    })
  }
}
</script>

